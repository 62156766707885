import NextLink from 'next/link'
import styles from './link.module.css'
import colors from 'styles/colors.module.css'
import { upperFirst } from 'lodash'

type Props = {
  children: React.ReactNode,
  href: string,
  color?: 'red' | 'black',
}

const Link = ({ children, color = 'red', href }: Props) => {
  const textColor = colors[`color${upperFirst(color)}`] as string
  return <NextLink href={ href }>
    <a className={styles.link} style={{ color: textColor }}>{ children }</a>
  </NextLink>
}

export default Link
