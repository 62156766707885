import * as styles from './landingSection.module.css'
import classNames from 'classnames'

type Props = {
  children: React.ReactNode,
  isPadded?: boolean,
}

const LandingSection = ({ isPadded = false, children }: Props) => <div className={classNames(styles.landingSection, { [styles.isPadded]: isPadded })}>
  { children }
</div>

export default LandingSection
