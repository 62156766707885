import sharedStyles from '../headline.module.css'

const Headline = () => <h1 className={sharedStyles.heading1}>
  Love is good<br />
  Chores are bad
  {/* Because when <strong><em>they</em> smile</strong>, it makes <strong><em>you</em>&nbsp;smile</strong> */}
  {/* Sometimes the little things make all the&nbsp;difference */}
</h1>

export default Headline
