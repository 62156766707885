import * as styles from './howItWorks.module.css'
import React from 'react'

class HowItWorks extends React.Component {

  // constructor(props:object) {
  //   super(props);

  //   this.enableStepRotation();
  // }

  // componentWillUnmount() {
  //   this.disableStepRotation()
  // }

  // state = {
  //   activeStep: 0,
  //   isHovering: false,
  // }

  // stepRotationTimer = null

  // enableStepRotation = () => {
  //   this.stepRotationTimer = setInterval(
  //     () => this.setActiveStep((this.state.activeStep + 1) % this.steps.length),
  //     7000
  //   )
  // }

  // disableStepRotation = () => {
  //   clearInterval(this.stepRotationTimer)
  // }

  // setActiveStep = (newStep:number) => {
  //   if (!this.state.isHovering) {
  //     this.setState({
  //       activeStep: newStep,
  //     })
  //   }
  // }

  // setActiveStepByHovering = (newStep:number) => () => {
  //   this.setState({
  //     activeStep: newStep,
  //     isHovering: true,
  //   })
  // }

  // setNotHovering = () => {
  //   this.setState({
  //     isHovering: false,
  //   })
  // }

  steps = [{
    index: 0,
    number: 1,
    title: 'Getting to know you',
    body: 'You tell CardHero what kind of cards you need.',
  }, {
    index: 1,
    number: 2,
    title: 'You pick from CardHero’s recommended&nbsp;cards',
    body: 'Based on our preselected, customizable bundles, or by developing your own personalized card profile using CardHero’s machine learning-based algorithms. Just a few taps and you’re&nbsp;finished.',
  }, {
    index: 2,
    number: 3,
    title: 'The cards arrive in your&nbsp;mailbox',
    body: 'It’s okay to dance a bit, knowing you won’t need to run to the store at the last minute or settle for a mediocre&nbsp;card. If any neighbors are staring, they’re just jealous.',
  }, {
    index: 3,
    number: 4,
    title: 'You hand write your message and give it to your&nbsp;recipient',
    body: '<p>They smile. You smile. Who knows what might come&nbsp;next?!?</p><br /><br /><h3>Later...</h3>',
  // }, {
  //   index: 5,
  //   number: 6,
  //   title: 'You give the&nbsp;card',
  //   body: '<p>You look great, and they never know just how easy it was.</p><br /><br /><h3>Later...</h3>',
  }, {
    index: 4,
    number: 5,
    title: 'CardHero plants trees in the Amazon',
    body: 'For every card purchased, enough trees are planted to produce more than 250 cards. Sure, most of the cards we sell are printed on 100% recycled paper, and all of our cards are recycleable, but can you complain about more trees?',
  }, {
    index: 5,
    number: 6,
    title: 'You never risk forgetting the card&nbsp;again',
    body: 'Next time your occasion comes around, CardHero messages you a reminder and a link to curated recommendations based on your personalized profile.',
  }, {
    index: 6,
    number: 7,
    title: 'You click even less than the first&nbsp;time',
    body: 'On your phone or computer, you pick a card with just a few taps. Remember, CardHero has already selected a list personalized just for you and the person you’re buying for.',
  }]

  render() {
    return <div className={styles.howItWorks}>
      {/* <div className={styles.heading}>
        <div className={styles.downArrow}>⬇</div>
        <div className={styles.learnMore}>How It Works</div>
      </div> */}
      <h2 className={styles.sectionTitle}>CardHero takes the hassle out of getting a card for someone special in your life. Here's how it works:</h2>
      {this.steps.map(step =>
        <div key={`stepMainContainer_${step.index}`} className={styles.stepActive}>
          <div className={styles.stepNumber}>{step.number}</div>
          <h3 className={styles.stepTitle} dangerouslySetInnerHTML={{ __html: step.title }} />
          <div className={styles.stepText} dangerouslySetInnerHTML={{ __html: step.body }} />
        </div>
      )}
      <h2 className={styles.sectionTitle}>CardHero helps you be more thoughtful with less thinking</h2>
    </div>
  }
}

export default HowItWorks
