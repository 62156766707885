import styles from './landingBody.module.css'
import Link from 'components/Link'
// import { DEFAULT_OCCASION_LABEL } from 'config/cardOptions'

type Props = {
  occasion?: string,
//   someoneYouLove?: string,
}

const LandingBody = ({ occasion = 'their birthday, your anniversary, Valentine’s day, and more' }: Props) => {
  // const isOccasionPlural = occasion?.includes('or ') || occasion?.includes('and ')
  return <div className={styles.body}>
    <p>CardHero mails you all the cards you need for { occasion }.</p>
    <p>Pick just one or <Link href='/bundles' color='black'>curate your own bundle</Link> and save even more time — and money&nbsp;too.</p>

    {/* <p>Giving { occasion } card should be fun, and buying it should be&nbsp;easy.</p>
    <p>CardHero mails you all the cards you need. Pick just one or <Link href='/bundles' color='black'>curate your own bundle</Link> and save even more time — and money too.</p> */}

    {/* <p><Link href='/bundles'>Customize a preselected set</Link>, craft your own bundle, or pick just one card. With just a few taps, it’s on its way to your mailbox.</p> */}
    {/* <p>Get all the birthday, anniversary, and other cards you need for your partner delivered straight to your mailbox – before you need&nbsp;them.</p> */}
    {/* <p>Shipping is 100% FREE. <Link href='/pricing'>Prices as low as <strong>$4.99</strong> per card</Link> when you&nbsp;bundle.</p>
    <p>Let’s reimagine how we give cards.</p> */}

    {/* <p>Let’s make them smile.</p> */}
    {/* <p><strong>CardHero delivers { !isOccasionPlural && 'a ' }better { occasion } card{ isOccasionPlural && 's' } for your partner straight to your mailbox before you need&nbsp;{ isOccasionPlural ? 'them' : 'it'}.</strong></p> */}
    {/* <p>All the cards you need for the year, selected just for you + your partner by our proprietary recommendation engine. Or just the one card you need right now.</p> */}
    {/* <p>Prices as low as <strong>$5</strong> per card when you <Link href='/pricing'>build your custom card bundle</Link> with help from our AI-powered recommendation engine.</p> */}
  </div>
}

export default LandingBody
