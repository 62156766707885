import styles from './sellingPoints.module.css'
import Icon from 'components/Icons/Icon'
import Link from 'components/Link'
import { colorWhite, colorMediumGrayText, colorDarkGrayText } from 'styles/colors.module.css'
import SatisfactionGuaranteedSeal from 'components/SatisfactionGuaranteedSeal'

const SellingPoints = () => <div className={styles.sellingPoints}>
  <h2>Why Buy Cards from CardHero?</h2>
  <ul className={styles.benefits}>
    <li>
      <div className={styles.title}>Zero-risk</div><p>Your happiness is <em>guaranteed</em>. This isn’t just an empty promise, it’s <Link href='/happinessguarantee'>literally guaranteed</Link>, or your money back.</p>
      <div className={styles.benefitIcon}>
        <SatisfactionGuaranteedSeal width={60} height={60} colorText={colorWhite} color1={colorMediumGrayText} color2={colorDarkGrayText} color3={colorDarkGrayText} /> {/*  color2='#bbb' color3='#bbb' colorText='#fff' */}
      </div>
    </li>
    <li>
      <div className={styles.title}>Four chores in three minutes</div><p>You <em>could</em> run to the store at the last minute, multiple times throughout the year, and search through a bunch of mediocre cards. Or you can spend a few minutes now, pick some great cards, and be done in a fraction of the time.</p><p>Your future self will thank you.</p>
      <div className={styles.benefitIcon}>
        <Icon icon='checklist' fill2={colorDarkGrayText} />
      </div>
    </li>
    <li>
      <div className={styles.title}>Better cards</div><p>High-quality paper. Less clichés. All sourced from independent artists.</p><p>We honestly think you should be <em>excited</em> to give your card.</p>
      <div className={styles.benefitIcon}>
        <Icon icon='cardLove' fill={colorDarkGrayText} fill2={colorDarkGrayText} />
      </div>
    </li>
    <li>
      <div className={styles.title}>Hugs for our planet</div><p>All of our cards are recyclable (unlike some of the cards you find at the store) and most are made from recycled or sustainably sourced paper.</p><p>Plus, every purchase helps <Link href='/trees'>plant&nbsp;trees</Link>.</p>
      <div className={styles.benefitIcon}>
        <Icon icon='globe' fill={colorDarkGrayText} />
      </div>
    </li>
  </ul>
  <h2>Even more good stuff</h2>
  <div>
    <div className={styles.plusses}>
      {/* <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='card' />
        </div>
        Cards for birthdays, anniversaries + more
      </div> */}
      {/* <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='envelope' fill={colorDarkGrayText} fill2={colorDarkGrayText} />
        </div>
        Mailed to you with<br />FREE&nbsp;SHIPPING in US
      </div> */}
      <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='brain' fill={colorDarkGrayText} fill2={colorDarkGrayText} />
        </div>
        Smart AI-powered card&nbsp;recommender
      </div>
      {/* <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='tree' />
        </div>
        <div>
          <Link href='/trees'><a>Help plant trees</a></Link> with<br />each&nbsp;purchase
        </div>
      </div> */}
      {/* <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='paintBrush' />
        </div>
        Quality cards from independent&nbsp;artists
      </div> */}
      {/* <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='smartPhone' />
        </div>
        Reminders so you<br />never&nbsp;forget
      </div> */}
      <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='thumbsUpFilled' fill={colorDarkGrayText} fill2={colorDarkGrayText} />
        </div>
        Support small&nbsp;business
      </div>
      <div className={styles.plus}>
        <div className={styles.plusIcon}>
          <Icon icon='lock' width={32} height={32} fill={colorDarkGrayText} fill2={colorDarkGrayText} />
        </div>
        Secure transactions protect your&nbsp;data
      </div>
    </div>
  </div>
  <div className={styles.theCatch}>
  <h2>What’s the catch?</h2>
    <div className={styles.theCatchContent}>
      <p>Let’s be completely honest. There is a catch here. One big reason that CardHero can ship you your cards for free, save you time, and sell cards for a reasonable price.</p>

      <p><strong>The catch is that you can’t wait until later.</strong></p>

      <p>It takes time for CardHero to process your order and ship your cards. If you need a card in the next few days, you’ll have to settle for the card aisle.</p>
      <p>But it only takes moments for you to set up CardHero. Do it now and you could save 30 - 60 minutes (or more!) per year, while getting better cards and saving the planet.</p>
      <p>Plus with our <Link color='black' href='/happinessguarantee'>Money-Back Happiness Guarantee</Link>, you’ve got nothing to lose by giving it a try.</p>
    </div>
  </div>

</div>

export default SellingPoints
