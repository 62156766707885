import Router from 'next/router'
import Button from '../Button'
import * as config from '../../shared/config'
import { useDispatch } from 'react-redux'
// import { createTempUser } from 'modules/User/actions'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import * as styles from './landingCTA.module.css'
import { createTempUser, useUser } from 'modules/User/remote'

interface LandingCTAProps {
  buttonText?: string,
  occasion?: string,
  children?: React.ReactNode,
  className?: string,
  href?: string,
}

const LandingCTA = ({ buttonText = 'Make them smile&nbsp;&raquo;', occasion, children = undefined, className = undefined, href = '/start/who' }: LandingCTAProps) => {
  const { user, mutateUser } = useUser()
  const userId = user?.id
  const [ctaButtonPushed, setCtaButtonPushed] = useState(false)
  const handleCtaClick = async () => {
    setCtaButtonPushed(true)
    await createTempUser()
    mutateUser()
  }
  useEffect(() => {
    console.log({ ctaButtonPushed, userId })
    if (ctaButtonPushed && userId) {
      Router.push(`${href}${ occasion ? `?occasion=${ occasion }` : '' }`)
    }
  }, [ctaButtonPushed, userId])
  return <div>
    <Button className={classNames(styles.cta, className)} onClick={() => { handleCtaClick() }} variant='large'>
      {
        children
          ? children
          : config.isWaitingListOn
            ? <span>Request EARLY ACCESS now&nbsp;&raquo;</span>
            : <span dangerouslySetInnerHTML={{ __html: buttonText }}>
          {/* <span>Start saving time&nbsp;&raquo; */}
          {/* <span>Yeah, I want to save time&nbsp;&raquo; */}
          {/* <span>Find your first card in &lt; 4 minutes&nbsp;&raquo; */}
              {/* <div className={styles.ctaSubtitle}>Then less chores FOREVER!</div> */}
            </span>
      }</Button>
  </div>
}

export default LandingCTA
